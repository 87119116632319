<template>
  <v-app>
    <div>
      <form class="form" @submit.stop.prevent="formOnsubmit()">
        <!--begin: Wizard Step 1-->
        <div
          class="pb-5"
          data-wizard-type="step-content"
          data-wizard-state="current"
        >
          <h5 class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg">
            <span>
              {{ title }}
            </span>
          </h5>

          <b-form-group id="input-group-date">
            <label for="input-date">Tanggal: </label>
            <v-menu
              v-model="menuDate1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <b-form-input
                  id="input-date"
                  v-model="form.date"
                  placeholder="Tanggal:"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></b-form-input>
              </template>
              <v-date-picker
                v-model="form.date"
                @input="menuDate1 = false"
              ></v-date-picker>
            </v-menu>
            <small class="text-danger">{{ error.date }}</small>
          </b-form-group>

          <b-form-group label="Jenis Stok:" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              id="radio-group-2"
              v-model="form.stock_in_out"
              :aria-describedby="ariaDescribedby"
              name="radio-sub-component"
            >
              <b-form-radio value="1">Stok Masuk</b-form-radio>
              <b-form-radio value="2">Stok Keluar</b-form-radio>
            </b-form-radio-group>
          </b-form-group>

          <b-form-group
            id="input-group-lab-material"
            label="Bahan:"
            label-for="input-lab-material"
          >
            <b-form-input
              id="input-lab-material"
              v-model="display.lab_material_name"
              placeholder="Pilih Bahan"
              readonly
              @click="$bvModal.show('modal-lab-material')"
            ></b-form-input>
            <small class="text-danger">{{ error.lab_material_id }}</small>
          </b-form-group>

          <b-form-group id="input-group-quantity">
            <label for="input-quantity">Jumlah:</label>
            <b-form-input
              id="input-quantity"
              v-model="form.quantity"
              placeholder="Jumlah"
            ></b-form-input>
            <small class="text-danger">{{ error.quantity }}</small>
          </b-form-group>

          <b-form-group id="input-group-description">
            <label for="input-description"
              >Keterangan: <em class="text-muted">opsional</em></label
            >
            <b-form-textarea
              id="input-description"
              v-model="form.description"
              placeholder="Keterangan"
              rows="4"
              max-rows="8"
            ></b-form-textarea>
            <small class="text-danger">{{ error.description }}</small>
          </b-form-group>
        </div>
        <!--end: Wizard Step 1-->

        <!--begin: Wizard Actions -->
        <div class="d-flex justify-content-between border-top pt-10">
          <div>
            <b-button type="submit" variant="primary">Simpan</b-button>
            <b-button
              type="button"
              class="ml-2"
              variant="default"
              @click="$router.push('/lab-material-stocks/list')"
            >
              Batal
            </b-button>
          </div>
        </div>
        <!--end: Wizard Actions -->
      </form>
    </div>

    <ModalLabMaterial @chooseData="chooseDataLabMaterial" />
  </v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import ModalLabMaterial from "@/view/components/general/ModalLabMaterial.vue";

export default {
  props: {
    form: Object,
    route: String,
    title: String,
    purpose: String,
  },
  components: {
    ModalLabMaterial,
  },
  data() {
    return {
      formData: new FormData(),
      error: {
        lab_material_id: "",
        date: "",
        stock_in_out: "",
        quantity: "",
        remaining_stock: "",
        user_id: "",
      },
      current_photo: "/img/default/tool-default.svg",
      default_photo: "/img/default/tool-default.svg",
      lab_material_categories: [],
      menuDate1: false,
      menuDate2: false,
      display: {
        lab_material_name: "",
      },
    };
  },
  methods: {
    onFileChange(e) {
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        this.form.photo_url = e.target.files[0];

        reader.onload = (event) => {
          this.current_photo = event.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    onRemove() {
      this.current_photo = null;
      this.form.photo_url = "";
    },
    async getLabMaterialCategoryOption() {
      let response = await module.setTreeSelect("api/lab-material-categories");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.lab_material_categories = response.data;
        this.lab_material_categories.unshift({
          label: "Pilih Kategori Bahan",
          id: "",
          isDisabled: true,
        });
      }
    },
    chooseDataLabMaterial(value) {
      if (value.remaining_stock == 0 && this.form.stock_in_out == 2) {
        Swal.fire({
          title: "Gagal",
          text: "Sisa stok bahan tersebut adalah 0 sehingga tidak bisa dikeluarkan",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
        });

        this.form.lab_material_id = 0;
        this.display.lab_material_name = '';
      } else {
        this.form.lab_material_id = value.id;
        this.display.lab_material_name = value.name;
        this.$bvModal.hide("modal-lab-material");
      }
    },
    async formOnsubmit() {
      for (const [key, value] of Object.entries(this.form)) {
        this.formData.append(key, value);
      }

      // Make Request
      let response = await module.submit(this.formData, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire({
          title: response.success.title,
          text: response.success.message,
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        this.$router.push("/lab-material-stocks");
      }
    },
  },
  mounted() {
    this.getLabMaterialCategoryOption();
  },
  computed: {
    formatedPrice: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ""), 10);
        if (!Number.isNaN(newValue)) {
          this.form.price = newValue.toLocaleString("id-ID");
        } else {
          this.form.price = "";
        }
      },
      get() {
        return this.form.price;
      },
    },
    photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    },
  },
  watch: {
    form: function (newVal, oldVal) {
      if (this.purpose == "edit") {
        this.form = this.form;

        this.default_photo = this.form.photo_url;
        this.current_photo = this.form.photo_url;
      }
    },
  },
};
</script>

<style scoped>
.image-input-wrapper {
  width: 230px !important;
  height: 230px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>